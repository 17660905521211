import {Location} from '@remix-run/router';
import {REG_COMMA_SEPARATED_EXP, REG_EXP} from "../module/AuthAdminModule";

export function capitalizeFirstLetter(parameter: string) {
  return parameter.charAt(0).toUpperCase() + parameter.slice(1);
}

export function isEmptyObj(obj: any) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

export const EMPTY_STRING: string = "";
export const EMPTY_STRING_ARRAY: string[] = [];
export const COLSPAN_12 = {colspan: 12};
const isMockRun = process.env.REACT_APP_MOCK_RUN;
// The url that the Test Server running at. Mimicking backend APIs
const testServerURL: string = "http://localhost:5000";
// currentApiDomain to use across UI to make calls to backend API's.
export const currentApiDomain: string = isMockRun
  ? testServerURL
  : window.origin.replace("https://", "https://api.");
export const REQUEST_INIT: RequestInit = isMockRun ? {}: {
  mode: "cors",
  credentials: "include",
}
export const HEADER_INIT: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json",
}
export const LOCATION_MAX_LENGTH = 7;
export const LOCATION_MIN_LENGTH = 3;
export const MAX_SITE_IDS_FOR_REPORT = 10;

export function getGridDefinition(length: number) {
  const elements: object[] = [];
  for (let element = 0; element < length; element++) {
    elements.push(COLSPAN_12);
  }
  return elements;
}

export function isEmptyString(value: string) {
  return value === undefined || value.trim().length === 0;
}

export function getServiceNameFromLocation(location: Location) {
  const crumbs = location.pathname.split("/");
  return crumbs[2].substring("name_".length).toUpperCase();
}

export function isEmptyArray(array: any[]) {
  return array === undefined || array.length === 0;
}

export function isAllowedAsInput(value: string) {
  return REG_EXP.test(value);
}

export function isAllowedAsCommaSeparatedInput(value: string) {
  return REG_COMMA_SEPARATED_EXP.test(value);
}

export function getTimeZoneAndFormat(date: Date) {
  return " (UTC-" + new Date(date).getTimezoneOffset() / 60 + ") (MM/DD/YYYY)";
}

export function validateSiteId(locationId: string, setErrorKey: Function, t: { translate: Function }) {
  if (locationId.length > LOCATION_MAX_LENGTH || locationId.length < LOCATION_MIN_LENGTH) {
    setErrorKey(t.translate("error_message_site_id_length"));
    return false;
  }
  if (isEmptyString(locationId)) {
    setErrorKey(t.translate("error_message_required_field"));
    return false;
  }
  if (!isAllowedAsInput(locationId)) {
    setErrorKey(t.translate("input_form_invalid_character_error_message"));
    return false;
  }
  return true;
};

export function isEmptyI18nVariable(variable: { [key: string]: any } | undefined) {
  return !variable || Object.keys(variable).length === 0;
}

export const REPORTING_OPERATIONS: Set<string> = new Set(['generateSiteReport', 'generateMultiSiteReport']);
export const USER_OPERATIONS: Set<string> = new Set(['pinReset', 'badgeStatus', 'reRegister']);
export const DEV_OPERATIONS: Set<string> = new Set(['createLocationAdmin']);

export interface OperationGroup {
  nameKey: string;
  order: number;
}

export const ReportingOperation: OperationGroup = {
  nameKey: "operations_group_reporting",
  order: 0,
}

export const UserOperation: OperationGroup = {
  nameKey: "operations_group_user_operation",
  order: 1,
}

export const DevOperation: OperationGroup = {
  nameKey: "operations_group_dev_operation",
  order: 2,
}

export function getOperationGroup(operation: string): OperationGroup | null {
  if (REPORTING_OPERATIONS.has(operation)) {
    return ReportingOperation;
  }
  if (USER_OPERATIONS.has(operation)) {
    return UserOperation;
  }
  if (DEV_OPERATIONS.has(operation)) {
    return DevOperation
  }
  return null;
}

export function getOrder(groupNameKey: string): number {
  if (groupNameKey === ReportingOperation.nameKey) {
    return ReportingOperation.order;
  }
  if (groupNameKey === UserOperation.nameKey) {
    return UserOperation.order;
  }
  if (groupNameKey === DevOperation.nameKey) {
    return DevOperation.order;
  }
  return 10;
}

export function pinResetInitiatedValueKey(value: string) {
  if ("true" === value) {
    return "field_value_yes";
  }
  if ("false" === value) {
    return "field_value_no";
  }
  return "field_value_unknown";
}

export function pinResetInitiatedValueColor(value: string) {
  if ("true" === value) {
    return "warning";
  }
  return "";
}

export function pinResetInitiatedTooltipKey(value: string) {
  if ("true" === value) {
    return "pin_reset_initiated_yes_tooltip";
  }
  return "";
}

export function pinResetInitiatedTooltipIcon(value: string) {
  if ("true" === value) {
    return "&#8505;";
  }
  return "";
}

export function showPinResetInitiatedTooltipIcon(value: string) {
  return "true" === value;
}

export function navigateToPinReset() {
  const href = window.location.href;
  window.location.href = href.substring(0, href.lastIndexOf("/")) + "/pinReset";
}